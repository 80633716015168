"use client";

import { ReactNode, useEffect, useRef, useState } from "react";

type FadeDirection = "top" | "bottom" | "left" | "right";

interface FadeInProps {
  children: ReactNode;
  delay?: number;
  duration?: number;
  className?: string;
  from?: FadeDirection;
}

export default function FadeIn({
  children,
  delay = 0,
  duration = 0.6,
  className = "",
  from = "bottom",
}: FadeInProps) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
        rootMargin: "50px",
      }
    );

    const currentElement = elementRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const getInitialTransform = (direction: FadeDirection): string => {
    const transformClasses = {
      top: "-translate-y-16",
      bottom: "translate-y-16",
      left: "-translate-x-16",
      right: "translate-x-16",
    };

    return transformClasses[direction];
  };

  return (
    <div
      ref={elementRef}
      className={`transform transition-all ease-out will-change-transform ${
        isVisible
          ? "opacity-100 translate-y-0 translate-x-0"
          : `opacity-0 ${getInitialTransform(from)}`
      } ${className}`}
      style={{
        transitionDelay: `${delay}s`,
        transitionDuration: `${duration}s`,
      }}
    >
      {children}
    </div>
  );
}
